import React from 'react'

const ErrorPage = () => {
    return (
        <section id="main">
            <div className="container">
                <h2 style={{ textAlign: 'center' }}>Error 404: Page not found!</h2>
            </div>
        </section>
    )
}

export default ErrorPage